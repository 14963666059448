import React, {
  useCallback,
  useEffect,
  useMemo,
  memo,
  useState,
  useRef,
} from "react";
import { useT } from "../../../hooks/useT";
import { Section } from "../../../layout/Section";
import { useMutation } from "react-query";
import { project, programme, release, product } from "../../../queries";
import { keys, isNil, isEmpty } from "ramda";
import { useToast } from "../../../hooks/useToast";
import { clientUrl } from "../../../util/routing";
import { uris } from "../../../config/nav";
import moment from "moment";
import { reportStatus } from "../../../util/constants";
import { RiskSection, IssueSection } from "../components/RiskAndIssueSections";
import { useQ } from "../../../hooks/useQ";
import { FormDateRangePicker } from "../../../base/form/mui/FormDateRangePicker";
import {
  Alert,
  AlertTitle,
  Button,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormRichTextField } from "../../../base/form/mui/FormRichTextField";
import { Stat } from "../../../base/Stat";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { FormDataProvider } from "../../../base/form/data-context/FormDataContext";
import { useNavigate, useBlocker } from "react-router-dom";
import { StagePlanForm } from "../Plans";
import { v4 as uuidV4 } from "uuid";
import { useFlag } from "@/hooks/useFlag";
import { FormMultiSelect } from "@/base/form/mui/FormMultiSelect";
import { LessonForm } from "../Lessons";
import { AddCircleOutline } from "@mui/icons-material";
import { BlockerAlert } from "@/base/BlockerAlert";
import { useConfirmLeavingPage } from "@/hooks/useConfirmLeavingPage";

function level2AndAbove(level) {
  return isNil(level) || level >= 2;
}

const RiskAndIssues = memo(
  ({
    risks,
    issues,
    refetchRisks = {},
    refetchIssues = {},
    type,
    isLoading,
    initiativeData,
  }) => {
    const report = useMemo(
      () => ({
        risk: {
          items: risks,
        },
        change: {
          items: issues,
        },
      }),
      [risks, issues],
    );

    return (
      <>
        {type === "project" && (
          <RiskSection
            report={report}
            refetchRisks={refetchRisks}
            type={type}
            viewOnly={false}
          />
        )}
        {type === "project" && (
          <IssueSection
            report={report}
            refetchRisks={refetchIssues}
            refetchIssues={refetchIssues}
            type={type}
            isLoading={isLoading}
            initiativeData={initiativeData}
            viewOnly={false}
          />
        )}
      </>
    );
  },
);

export const HighlightReportForm = ({
  data,
  isLoadingRisks,
  risks,
  refetchRisks,
  refetchIssues,
  isLoadingIssues,
  issues,
  highlights,
  level,
  onCreate,
  closeHref,
  state = "add",
  type = "highlight",
  currentStage,
  initialValues,
  reportId,
  initiativeType,
  initiativeId,
  initiativeName,
  initiativeData,
  lessons,
  refetchProjectLessons,
}) => {
  const { t } = useT();
  const toast = useToast();
  const useOptimizedReports = useFlag("project.optimize.reports");
  const latestReports = useMemo(() => {
    return !!useOptimizedReports
      ? highlights
      : highlights
          .slice()
          .filter((item) => item.id === reportId)
          .sort((a, b) =>
            a.isDraft
              ? 1
              : b.isDraft
                ? -1
                : moment(a.date).isBefore(moment(b.date))
                  ? -1
                  : moment(b.date).isBefore(moment(a.date))
                    ? 1
                    : 0,
          )
          .slice(-1);
  }, [highlights, useOptimizedReports]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const previousReportId =
    state === "edit" ? highlights[1]?.id : highlights[0]?.id;

  const { data: previousReportContent = {}, isLoading: isLoadingPrevious } =
    useQ(
      `project-${initiativeId}-report-${previousReportId}`,
      () =>
        project.report.single({
          id: initiativeId,
          type,
          reportId: previousReportId,
        }),
      {
        enabled:
          !!useOptimizedReports &&
          initiativeType === "project" &&
          previousReportId !== undefined,
      },
    );

  const previousReport = !!useOptimizedReports ? previousReportContent : data;

  const schema = yup.object().shape({
    isDraft: yup.boolean(),
    from: yup.string().trim().required(),
    to: yup.string().trim().required(),
    followUps: yup.string().trim(),
    summary: yup.string().trim(),
    summaryStatus: yup.string(),
    thisPeriod: yup.string().trim(),
    nextPeriod: yup.string().trim(),
    benefits: yup.string().trim(),
    benefitsStatus: yup.string().nullable(),
    time: yup.string().trim(),
    timeStatus: yup.string().nullable(),
    cost: yup.string().trim(),
    costStatus: yup.string().nullable(),
    scope: yup.string().trim(),
    scopeStatus: yup.string().nullable(),
    risk: yup.string().trim(),
    riskStatus: yup.string().nullable(),
    teamPerformance: yup.string().trim(),
    lessonsDescription: yup.string().trim(),
    lessons: yup.array().of(yup.string().trim()),
    performedAchivements: yup.string().trim(),
    performedAchievements: yup.string().trim(),
    plannedAchivements: yup.string().trim(),
    workPackage: yup.string().trim(),
  });
  const formData = useForm({
    resolver: yupResolver(schema),
    defaultValues: Object.assign(
      {
        isDraft: true,
        summary: "",
        followUps: "",
        summaryStatus: "",
        performedAchivements: "",
        performedAchievements: "",
        plannedAchivements: "",
        benefits: "",
        benefitsStatus: "",
        time: "",
        timeStatus: "",
        cost: "",
        costStatus: "",
        quality: "",
        qualityStatus: "",
        scope: "",
        scopeStatus: "",
        risk: "",
        riskStatus: "",
        teamPerformance: "",
        lessonsDescription: "",
        lessons: [],
        from: "",
        to: "",
        workPackage: "",
      },
      initialValues,
    ),
  });
  const {
    reset,
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
    setValue,
  } = formData;

  useEffect(() => {
    const latestReport = !!useOptimizedReports
      ? [initialValues]
      : latestReports;
    reset(
      Object.assign(
        {
          isDraft: true,
          summary: latestReport?.[0]?.summary?.content,
          summaryStatus: latestReport?.[0]?.summary?.status,
          performedAchievements: latestReport?.[0]?.performedAchivements,
          plannedAchievements: latestReport?.[0]?.plannedAchivements,
          benefits: latestReport?.[0]?.benefits?.content,
          benefitsStatus: latestReport?.[0]?.benefits?.status ?? "",
          time: latestReport?.[0]?.time?.content,
          timeStatus: latestReport?.[0]?.time?.status ?? "",
          cost: latestReport?.[0]?.cost?.content,
          costStatus: latestReport?.[0]?.cost?.status ?? "",
          quality: latestReport?.[0]?.quality?.content,
          qualityStatus: latestReport?.[0]?.quality?.status ?? "",
          scope: latestReport?.[0]?.scope?.content,
          scopeStatus: latestReport?.[0]?.scope?.status ?? "",
          risk: latestReport?.[0]?.risk?.content,
          riskStatus: latestReport?.[0]?.risk?.status ?? "",
          from: moment.utc(latestReport?.[0]?.from),
          to: moment.utc(latestReport?.[0]?.date),
          teamPerformance: latestReport?.[0]?.teamPerformance,
          lessonsDescription: latestReport?.[0]?.lessonsDescription,
          lessons: latestReport?.[0]?.lessons ?? [],
          workPackage: latestReport?.[0]?.workPackage ?? "",
          followUps: latestReport?.[0]?.followUps,
        },
        !!useOptimizedReports
          ? state === "add"
            ? {
                performedAchievements:
                  previousReportContent?.plannedAchivements,
              }
            : {}
          : initialValues,
      ),
    );
  }, [initialValues, isLoadingPrevious, useOptimizedReports, reset]);

  const statusOptions = [
    ...keys(reportStatus).map((status) => ({
      value: reportStatus[status],
      label: (
        <Stack direction="row" spacing={1} alignItems="center">
          <Stat status={status} />
          <span>
            {t(
              `project.progress.${
                type === "endStage" ? "endStageReport" : "highlightReport"
              }.fields.status.${status}`,
            )}
          </span>
        </Stack>
      ),
    })),
  ];

  const onCreateNewReport = useMemo(
    () =>
      handleSubmit(async (formContent) => {
        const report = {
          isDraft: formContent.isDraft,
          type: type,
          value: formContent.summaryStatus,
          description: formContent.summary,
          date: moment.utc(formContent.to).format("YYYY-MM-DD"),
          from: moment.utc(formContent.from).format("YYYY-MM-DD"),
          performedAchievements: formContent.performedAchievements,
          plannedAchievements: formContent.plannedAchievements,
          teamPerformance: formContent.teamPerformance,
          lessonsDescription: formContent.lessonsDescription,
          lessons: formContent.lessons,
          stageId: type === "endStage" ? data?.stage?.id : undefined,
          workPackage: formContent.workPackage,
          followUps: formContent.followUps,
          details:
            type !== "checkpoint"
              ? [
                  !isNil(formContent.benefitsStatus) ||
                  !isNil(formContent.benefits)
                    ? {
                        name: "benefits",
                        value:
                          formContent.benefitsStatus !== ""
                            ? formContent.benefitsStatus
                            : null,
                        description: formContent.benefits,
                      }
                    : undefined,
                  !isNil(formContent.timeStatus) || !isNil(formContent.time)
                    ? {
                        name: "time",
                        value:
                          formContent.timeStatus !== ""
                            ? formContent.timeStatus
                            : null,
                        description: formContent.time,
                      }
                    : undefined,
                  !isNil(formContent.costStatus) || !isNil(formContent.cost)
                    ? {
                        name: "cost",
                        value:
                          formContent.costStatus !== ""
                            ? formContent.costStatus
                            : null,
                        description: formContent.cost,
                      }
                    : undefined,
                  !isNil(formContent.qualityStatus) ||
                  !isNil(formContent.quality)
                    ? {
                        name: "quality",
                        value:
                          formContent.qualityStatus !== ""
                            ? formContent.qualityStatus
                            : null,
                        description: formContent.quality,
                      }
                    : undefined,
                  !isNil(formContent.scopeStatus) || !isNil(formContent.scope)
                    ? {
                        name: "scope",
                        value:
                          formContent.scopeStatus !== ""
                            ? formContent.scopeStatus
                            : null,
                        description: formContent.scope,
                      }
                    : undefined,
                  !isNil(formContent.riskStatus) || !isNil(formContent.risk)
                    ? {
                        name: "risk",
                        value:
                          formContent.riskStatus !== ""
                            ? formContent.riskStatus
                            : null,
                        description: formContent.risk,
                      }
                    : undefined,
                  !isNil(formContent.progressStatus) ||
                  !isNil(formContent.progress)
                    ? {
                        name: "progress",
                        value:
                          formContent.progressStatus !== ""
                            ? formContent.progressStatus
                            : null,
                        description: formContent.progress,
                      }
                    : undefined,
                  !isNil(formContent.resourceSstatus) ||
                  !isNil(formContent.resources)
                    ? {
                        name: "resources",
                        value:
                          formContent.resourcesStatus !== ""
                            ? formContent.resourcesStatus
                            : null,
                        description: formContent.resources,
                      }
                    : undefined,
                ].filter((item) => !isNil(item))
              : undefined,
          endProject: type === "endProject" ? true : undefined,
        };
        setIsSubmitting(true);
        onCreate(
          report,
          formContent.isDraft,
          () => {},
          () => {
            setIsSubmitting(false);
          },
        );
      }),
    [handleSubmit, onCreate],
  );

  const handleSaveDraft = useCallback(() => {
    setValue("isDraft", true);
    onCreateNewReport();
  }, [onCreateNewReport, setValue]);

  const handlePublish = useCallback(() => {
    setValue("isDraft", false);
    onCreateNewReport();
  }, [onCreateNewReport, setValue]);

  useConfirmLeavingPage(isDirty);

  const lessonsOptions = useMemo(
    () =>
      lessons?.map((lesson) => ({
        id: lesson.id,
        value: lesson.title,
      })),
    [lessons],
  );

  const useIssueLessons = useFlag("lessons.issues");
  const useRestApiV1 = useFlag("lessons.useRestApiV1");
  const addLesson = useMutation(project.addLesson, {
    onSuccess: (data) => {
      console.log("Received data: " + JSON.stringify(data));
      toast.success("Lesson created successfully");
      setShowNewItemForm(false);
      refetchProjectLessons();
    },
    onError: (error) => {
      console.log("Received error: " + JSON.stringify(error));
      toast.error("Error creating lesson");
    },
  });

  const [showNewItemForm, setShowNewItemForm] = useState(false);
  const [newLessonId, setNewLessonId] = useState(null);
  const handleCreateLesson = useCallback(
    async (data) => {
      const lessonId = uuidV4();
      setNewLessonId(lessonId);
      await addLesson.mutate({
        useRestApiV1,
        projectId: initiativeId,
        lesson: {
          ...data,
          id: lessonId,
        },
      });
    },
    [initiativeId, useRestApiV1],
  );

  const [lessonsFormData] = useWatch({
    control: formData.control,
    name: ["lessons"],
  });

  useEffect(() => {
    if (newLessonId) {
      if (!lessonsFormData?.includes(newLessonId)) {
        setValue("lessons", [...lessonsFormData, newLessonId]);
      }
      setNewLessonId(null);
    }
  }, [lessonsFormData, lessons]);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname &&
      !!isDirty &&
      !isSubmitting,
  );

  return (
    <>
      <BlockerAlert blocker={blocker} />
      {type === "endStage" && (
        <Section
          title={t("project.progress.endStageReport.fields.stage.title")}
        >
          <StagePlanForm
            historicProjectPlan={[currentStage]}
            showSum={false}
            viewOnly
          />
          <Button variant="outlined" onClick={() => alert("Not supported yet")}>
            {t("project.progress.endStageReport.fields.stage.selectStage")}
          </Button>
        </Section>
      )}
      <FormDataProvider
        formKey={`project.progress.${
          type === "endStage"
            ? "endStageReport"
            : type === "endProject"
              ? "endProjectReport"
              : "highlightReport"
        }.fields`}
        {...formData}
      >
        <form onSubmit={onCreateNewReport}>
          <div id="dirtyFields" style={{ display: "none" }}>
            {JSON.stringify(dirtyFields ?? {})}
          </div>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h1">
              {t("project.progress.highlightReport.fields.period.title")} *
            </Typography>
            <FormDateRangePicker
              label={(from, to) => {
                return (
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Typography>{`${t(
                      "project.progress.highlightReport.fields.period.from",
                    )} ${moment(from).format("YYYY-MM-DD")} ${t(
                      "project.progress.highlightReport.fields.period.to",
                    )} ${moment(to).format("YYYY-MM-DD")}`}</Typography>
                    <EditOutlinedIcon />
                  </Stack>
                );
              }}
              minName="from"
              maxName="to"
            />
          </Stack>
          <Divider
            sx={{
              mt: 1,
              mb: 4,
            }}
          />
          <Stack spacing={3}>
            <FormRichTextField
              name="summary"
              select={{
                name: "summaryStatus",
                options: statusOptions,
              }}
              previous={{
                text: previousReport?.summary?.content,
                status: previousReport?.summary?.status,
              }}
            />
            {type === "checkpoint" && (
              <FormRichTextField
                name="followUps"
                previous={{
                  text: previousReport?.followUps,
                }}
              />
            )}
            {type !== "endStage" ? (
              <>
                <FormRichTextField
                  name="performedAchievements"
                  previous={{
                    text: previousReport?.performedAchivements,
                  }}
                />

                {type !== "endProject" && (
                  <FormRichTextField
                    name="plannedAchievements"
                    previous={{
                      text: previousReport?.plannedAchivements,
                    }}
                  />
                )}
              </>
            ) : (
              <FormRichTextField
                name="performedAchievements"
                label={t(
                  "project.progress.highlightReport.fields.deliveredThisStage.title",
                )}
                placeholder={t(
                  "project.progress.highlightReport.fields.deliveredThisStage.description",
                )}
                help={t(
                  "project.progress.highlightReport.fields.deliveredThisStage.help",
                )}
                previous={{
                  text: previousReport?.performedAchivements,
                }}
              />
            )}
            {type === "checkpoint" && (
              <FormRichTextField
                name="workPackage"
                previous={{
                  text: previousReport?.workPackage,
                }}
              />
            )}
            {level2AndAbove(level) && type !== "checkpoint" ? (
              <>
                <FormRichTextField
                  name="benefits"
                  select={{
                    name: "benefitsStatus",
                    options: statusOptions,
                  }}
                  previous={{
                    text: previousReport?.benefits?.content,
                    status: previousReport?.benefits?.status,
                  }}
                />
                <FormRichTextField
                  name="time"
                  select={{
                    name: "timeStatus",
                    options: statusOptions,
                  }}
                  previous={{
                    text: previousReport?.time?.content,
                    status: previousReport?.time?.status,
                  }}
                />
                <FormRichTextField
                  name="cost"
                  select={{
                    name: "costStatus",
                    options: statusOptions,
                  }}
                  previous={{
                    text: previousReport?.cost?.content,
                    status: previousReport?.cost?.status,
                  }}
                />
                <FormRichTextField
                  name="quality"
                  select={{
                    name: "qualityStatus",
                    options: statusOptions,
                  }}
                  previous={{
                    text: previousReport?.quality?.content,
                    status: previousReport?.quality?.status,
                  }}
                />
                <FormRichTextField
                  name="scope"
                  select={{
                    name: "scopeStatus",
                    options: statusOptions,
                  }}
                  previous={{
                    text: previousReport?.scope?.content,
                    status: previousReport?.scope?.status,
                  }}
                />
                <FormRichTextField
                  name="risk"
                  select={{
                    name: "riskStatus",
                    options: statusOptions,
                  }}
                  previous={{
                    text: previousReport?.risk?.content,
                    status: previousReport?.risk?.status,
                  }}
                />
                {type === "endStage" || type === "endProject" ? (
                  <>
                    <FormRichTextField
                      name="teamPerformance"
                      previous={{
                        text: previousReport?.teamPerformance,
                      }}
                    />
                    <FormRichTextField
                      name="lessonsDescription"
                      previous={{
                        text: previousReport?.lessonsDescription,
                      }}
                    />
                    {useIssueLessons && (
                      <>
                        <FormMultiSelect
                          label=""
                          help=""
                          name="lessons"
                          options={lessonsOptions}
                        />
                        <Stack direction="row" justifyContent="flex-start">
                          <Button
                            variant="outlined"
                            endIcon={<AddCircleOutline />}
                            onClick={() => setShowNewItemForm(true)}
                          >
                            {t("project.plans.lessons.actions.create")}
                          </Button>
                        </Stack>
                      </>
                    )}
                  </>
                ) : null}
              </>
            ) : null}
          </Stack>
        </form>
      </FormDataProvider>
      {!isLoadingRisks && !isLoadingIssues ? (
        <RiskAndIssues
          risks={risks}
          issues={issues}
          refetchRisks={refetchRisks}
          refetchIssues={refetchIssues}
          type={initiativeType}
          isLoading={isLoadingIssues && isLoadingRisks}
          initiativeData={initiativeData}
        />
      ) : (
        <p>Loading risks and issues</p>
      )}
      <Stack mt={2} direction="row" spacing={2} justifyContent="flex-end">
        <Button href={closeHref} color="secondary">
          {t("generic.button.close")}
        </Button>
        <Button
          variant="contained"
          onClick={handleSaveDraft}
          disabled={!errors}
          color="secondary"
        >
          {t("project.progress.highlightReport.submit.draftReport")}
        </Button>
        <Button
          variant="contained"
          disabled={!errors}
          color="primary"
          onClick={handlePublish}
        >
          {t("project.progress.highlightReport.submit.createReport")}
        </Button>
      </Stack>
      <LessonForm
        open={showNewItemForm}
        projectData={initiativeData}
        onCancel={() => setShowNewItemForm(false)}
        onSubmit={handleCreateLesson}
      />
    </>
  );
};

export const HighlightProgressReportForm = ({
  projectId,
  level,
  state = "add",
  reportId,
  type,
}) => {
  const { t } = useT();
  const toast = useToast();
  const navigate = useNavigate();
  const useRestApiV1 = useFlag("checkpoints.useRestApiV1");
  const useOptimizedReports = useFlag("project.optimize.reports");

  const { data = {} } = useQ(`project-${projectId}`, () =>
    project.single({ id: projectId }),
  );
  const {
    isLoading: isLoadingRisks,
    data: risks = {},
    refetch: refetchRisks,
  } = useQ(`project-${projectId}-risks`, () =>
    project.risks({ id: projectId }),
  );
  const {
    isLoading: isLoadingIssues,
    data: issues = {},
    refetch: refetchIssues,
  } = useQ(`project-${projectId}-issues`, () =>
    project.issues({ id: projectId }),
  );
  const { data: highlights = [], isFetched } = useQ(
    `project-${projectId}-report-highlights`,
    () =>
      project.report.highlights({
        id: projectId,
      }),
    { enabled: !useOptimizedReports },
  );

  const { data: reportList = [], isFetched: fetchedReports } = useQ(
    `project-${projectId}-report-list`,
    () =>
      project.report.list({
        id: projectId,
      }),
    { enabled: !!useOptimizedReports },
  );

  const { data: projectPlanData = [] } = useQ(
    `project-${projectId}-plans`,
    () => project.plans({ id: projectId }),
    { enabled: type === "endStage" || type === "endProject" },
  );
  const latestStage = useMemo(
    () => projectPlanData?.stages?.filter((stage) => stage.active === true)[0],
    [projectPlanData],
  );
  const { data: checkpoints = [] } = useQ(
    `project-${projectId}-report-checkpoints`,
    () => project.report.checkpoints({ id: projectId }),
    { enabled: type === "checkpoint" },
  );

  const { data: projectLessonsData = {}, refetch: refetchProjectLessons } =
    useQ(`project-lessons`, () => project.lessons());

  const lessons = projectLessonsData.lessons ?? [];

  const createHighlightReport = useMutation(
    type === "checkpoint" && useRestApiV1
      ? project.createCheckpointReport
      : project.createHighlightReport,
    {
      onSuccess: (data) => {
        navigate(
          clientUrl(uris.project.progressFull, {
            id: projectId,
            reportId: data.id?.report?.id ?? data.report?.id,
            type: type,
          }),
        );
      },
      onError: () => {
        toast.error("Unfortunately is it not possible to publish reports, yet"); // t('project.progress.highlightReport.messages.error.generic'));
      },
    },
  );

  const handleCreate = useCallback(
    async (report, isDraft, onSuccess, onError) => {
      try {
        await createHighlightReport.mutate(
          {
            useRestApiV1,
            id: uuidV4(),
            ...report,
            projectId,
          },
          {
            onSuccess: () => {
              if (!isDraft) {
                toast.success(
                  t(
                    "project.progress.highlightReport.messages.success.createdReport",
                  ),
                );
              } else {
                toast.success("Report draft saved successfully");
                navigate(
                  clientUrl(uris.project.progressFull, {
                    id: projectId,
                    reportId: "draft",
                    type: type,
                  }),
                );
              }
              onSuccess?.();
            },
          },
        );
      } catch (e) {
        onError?.(e);
      }
    },
    [createHighlightReport, useRestApiV1],
  );

  const editHighlightReport = useMutation(project.editHighlightReport, {
    onSuccess: (data) => {
      // next();
    },
    onError: () => {
      toast.error("Unfortunately is it not possible to publish reports, yet");
    },
  });

  const handleEdit = useCallback(
    (report, isDraft) => {
      editHighlightReport.mutate(
        {
          ...report,
          projectId,
          reportId,
        },
        {
          onSuccess: () => {
            if (!isDraft) {
              toast.success(
                t(
                  "project.progress.highlightReport.messages.success.createdReport",
                ),
              );
            } else {
              toast.success("Report draft saved successfully");
            }
          },
        },
      );
    },
    [editHighlightReport],
  );

  const reports = useMemo(() => {
    const filterType = isEmpty(type) ? "highlight" : type;
    return (
      useOptimizedReports
        ? reportList.filter((report) => report.type === filterType)
        : type === "checkpoint"
          ? useRestApiV1
            ? highlights.filter((report) => report.type === "checkpoint")
            : checkpoints
          : type === "highlight" || isEmpty(type)
            ? highlights.filter(
                (report) =>
                  report.endProject !== true &&
                  isNil(report.stage) &&
                  report.type !== "checkpoint",
              )
            : type === "endStage"
              ? highlights.filter(
                  (report) =>
                    !isNil(report.stage) &&
                    report.endProject !== true &&
                    report.type !== "checkpoint",
                )
              : type === "endProject"
                ? highlights.filter(
                    (report) =>
                      report.endProject === true &&
                      report.type !== "checkpoint",
                  )
                : []
    )
      .slice()
      .sort((a, b) =>
        a.isDraft
          ? 1
          : b.isDraft
            ? -1
            : moment(a.date).isBefore(moment(b.date))
              ? -1
              : moment(b.date).isBefore(moment(a.date))
                ? 1
                : a.timestamp < b.timestamp
                  ? -1
                  : a.timestamp > b.timestamp
                    ? 1
                    : 0,
      )
      .reverse();
  }, [highlights, checkpoints, type, useRestApiV1, fetchedReports]);

  const { data: latestReportContent = {}, isLoading } = useQ(
    `project-${projectId}-report-${reports[0]?.id}`,
    () =>
      project.report.single({
        id: projectId,
        type,
        reportId: reports[0]?.id,
      }),
    {
      enabled: !!useOptimizedReports && reports?.length > 0,
    },
  );
  const latestReportContentStr = JSON.stringify(latestReportContent);

  const initialValues = useMemo(() => {
    const latestReportContent = JSON.parse(latestReportContentStr);
    return state === "edit"
      ? latestReportContent
      : type === "endStage"
        ? {
            performedAchievements: latestStage?.scheduleComments?.replace?.(
              /\n/g,
              "",
            ),
            from: moment.utc(latestStage?.time?.start),
            to: moment.utc(),
          }
        : type === "endProject"
          ? {
              from: moment.utc(
                projectPlanData?.stages
                  ? projectPlanData?.stages[0]?.time?.start
                  : "",
              ),
              to: moment.utc(),
            }
          : {
              performedAchievements: !!useOptimizedReports
                ? latestReportContent?.plannedAchivements
                : reports[0]?.plannedAchivements,
              from: moment.utc(reports[0]?.date),
              to: moment.utc(),
            };
  }, [
    latestReportContentStr,
    latestStage,
    reports,
    state,
    type,
    useOptimizedReports,
    projectPlanData,
  ]);

  return (
    <>
      <HighlightReportForm
        data={
          !isNil(
            reports.length > 1 && state === "edit" ? reports[1] : reports[0],
          )
            ? Object.assign(
                reports.length > 1 && state === "edit"
                  ? reports[1]
                  : reports[0],
                { stage: data.stage },
              )
            : data
        }
        isLoadingRisks={isLoadingRisks}
        risks={risks}
        isLoadingIssues={isLoadingIssues}
        issues={issues}
        highlights={reports}
        level={level}
        onCreate={handleCreate}
        closeHref={clientUrl(uris.project.single, { id: projectId })}
        state={state}
        type={type}
        currentStage={latestStage}
        initialValues={initialValues}
        reportId={reportId}
        refetchRisks={refetchRisks}
        refetchIssues={refetchIssues}
        initiativeType={"project"}
        initiativeId={projectId}
        initiativeName={data.name}
        initiativeData={data}
        lessons={lessons}
        refetchProjectLessons={refetchProjectLessons}
      />
    </>
  );
};

export const HighlightProductProgressReportForm = ({
  productId,
  level,
  state = "add",
  reportId,
  type,
}) => {
  const { t } = useT();
  const toast = useToast();
  const navigate = useNavigate();
  const { data = {} } = useQ(`product-${productId}`, () =>
    product.single({ id: productId }),
  );
  const { data: highlights = [] } = useQ(
    `product-${productId}-report-highlights`,
    () => product.report.highlights({ id: productId }),
  );
  const createHighlightReport = useMutation(product.createHighlightReport, {
    onSuccess: (data) => {
      navigate(
        clientUrl(uris.product.progressFull, {
          id: productId,
          reportId: data.id.report.id,
          type: type,
        }),
      );
    },
    onError: () => {
      toast.error("Unfortunately is it not possible to publish reports, yet"); // t('product.progress.highlightReport.messages.error.generic'));
    },
  });

  const handleCreate = useCallback(
    (report, isDraft, onSuccess, onError) => {
      try {
        createHighlightReport.mutate(
          {
            ...report,
            productId,
          },
          {
            onSuccess: () => {
              if (!isDraft) {
                toast.success(
                  t(
                    "product.progress.highlightReport.messages.success.createdReport",
                  ),
                );
              } else {
                toast.success("Report draft saved successfully");
                navigate(
                  clientUrl(uris.product.progressFull, {
                    id: productId,
                    reportId: "draft",
                    type: type,
                  }),
                );
              }
              onSuccess?.();
            },
          },
        );
      } catch (e) {
        onError?.(e);
      }
    },
    [createHighlightReport],
  );

  const editHighlightReport = useMutation(product.editHighlightReport, {
    onSuccess: (data) => {
      // next();
    },
    onError: () => {
      toast.error("Unfortunately is it not possible to publish reports, yet");
    },
  });

  const handleEdit = useCallback(
    (report, isDraft) => {
      editHighlightReport.mutate(
        {
          ...report,
          productId,
          reportId,
        },
        {
          onSuccess: () => {
            if (!isDraft) {
              toast.success(
                t(
                  "product.progress.highlightReport.messages.success.createdReport",
                ),
              );
            } else {
              toast.success("Report draft saved successfully");
            }
          },
        },
      );
    },
    [editHighlightReport],
  );

  return (
    <HighlightReportForm
      data={data}
      highlights={highlights}
      level={level}
      onCreate={handleCreate}
      closeHref={clientUrl(uris.product.single, { id: productId })}
      state={state}
      type={type}
      reportId={reportId}
      initiativeType={"product"}
    />
  );
};

export const HighlightKnowledgeReportForm = ({
  programmeId,
  level,
  state = "add",
  reportId,
  type,
}) => {
  const { t } = useT();
  const toast = useToast();
  const navigate = useNavigate();
  const { data = {} } = useQ(`programme-${programmeId}`, () =>
    programme.single({ id: programmeId }),
  );
  const { data: highlights = [] } = useQ(
    `programme-${programmeId}-report-highlights`,
    () => programme.report.highlights({ id: programmeId }),
  );

  const createHighlightReport = useMutation(programme.createHighlightReport, {
    onSuccess: (data) => {
      // next();
      navigate(
        clientUrl(uris.programme.knowledgeFull, {
          id: programmeId,
          reportId: data.id.report.id,
          type: type,
        }),
      );
    },
    onError: () => {
      toast.error("Unfortunately is it not possible to publish reports, yet");
    },
  });

  const handleCreate = useCallback(
    (report, isDraft, onSuccess, onError) => {
      try {
        createHighlightReport.mutate(
          {
            ...report,
            programmeId,
          },
          {
            onSuccess: () => {
              if (!isDraft) {
                toast.success(
                  t(
                    "project.progress.highlightReport.messages.success.createdReport",
                  ),
                );
              } else {
                toast.success("Report draft saved successfully");
                navigate(
                  clientUrl(uris.programme.knowledgeFull, {
                    id: programmeId,
                    reportId: "draft",
                    type: type,
                  }),
                );
              }
              onSuccess?.();
            },
          },
        );
      } catch (e) {
        onError?.(e);
      }
    },
    [createHighlightReport],
  );

  const editHighlightReport = useMutation(programme.editHighlightReport, {
    onSuccess: (data) => {
      // next();
    },
    onError: () => {
      toast.error("Unfortunately is it not possible to publish reports, yet");
    },
  });

  const handleEdit = useCallback(
    (report, isDraft) => {
      editHighlightReport.mutate(
        {
          ...report,
          programmeId,
          reportId: reportId,
        },
        {
          onSuccess: () => {
            if (!isDraft) {
              toast.success(
                t(
                  "project.progress.highlightReport.messages.success.createdReport",
                ),
              );
            } else {
              toast.success("Report draft saved successfully");
            }
          },
        },
      );
    },
    [editHighlightReport],
  );

  return (
    <HighlightReportForm
      data={data}
      highlights={highlights}
      level={level}
      onCreate={handleCreate}
      closeHref={clientUrl(uris.programme.single, { id: programmeId })}
      state={state}
      reportId={reportId}
      initiativeType={"programme"}
    />
  );
};

export const HighlightReleaseProgressReportForm = ({
  releaseId,
  level,
  state = "add",
  reportId,
  type,
}) => {
  const { t } = useT();
  const toast = useToast();
  const navigate = useNavigate();
  const { data = {} } = useQ(`release-${releaseId}`, () =>
    release.single({ id: releaseId }),
  );
  const { data: highlights = [] } = useQ(
    `release-${releaseId}-report-highlights`,
    () => release.report.highlights({ id: releaseId }),
  );

  const createHighlightReport = useMutation(
    release.report.createHighlightReport,
    {
      onSuccess: (data) => {
        // next();
        navigate(
          clientUrl(uris.release.progressFull, {
            id: releaseId,
            reportId: data.id.report.id,
            type: type,
          }),
        );
      },
      onError: () => {
        toast.error("Unfortunately is it not possible to publish reports, yet");
      },
    },
  );

  const handleCreate = useCallback(
    (report, isDraft, onSuccess, onError) => {
      try {
        createHighlightReport.mutate(
          {
            ...report,
            releaseId,
          },
          {
            onSuccess: () => {
              if (!isDraft) {
                toast.success(
                  t(
                    "project.progress.highlightReport.messages.success.createdReport",
                  ),
                );
              } else {
                toast.success("Report draft saved successfully");
                navigate(
                  clientUrl(uris.release.progressFull, {
                    id: releaseId,
                    reportId: "draft",
                    type: type,
                  }),
                );
              }
              onSuccess?.();
            },
          },
        );
      } catch (e) {
        onError?.(e);
      }
    },
    [createHighlightReport],
  );

  const editHighlightReport = useMutation(release.report.editHighlightReport, {
    onSuccess: (data) => {
      // next();
    },
    onError: () => {
      toast.error("Unfortunately is it not possible to publish reports, yet");
    },
  });

  const handleEdit = useCallback(
    (report, isDraft) => {
      editHighlightReport.mutate(
        {
          ...report,
          releaseId,
          reportId: reportId,
        },
        {
          onSuccess: () => {
            if (!isDraft) {
              toast.success(
                t(
                  "project.progress.highlightReport.messages.success.createdReport",
                ),
              );
            } else {
              toast.success("Report draft saved successfully");
            }
          },
        },
      );
    },
    [editHighlightReport],
  );

  return (
    <HighlightReportForm
      data={data}
      highlights={highlights}
      level={level}
      onCreate={handleCreate}
      closeHref={clientUrl(uris.release.single, { id: releaseId })}
      state={state}
      reportId={reportId}
      initiativeType={"release"}
    />
  );
};
